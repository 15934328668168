@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');





@keyframes swing {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(10deg);
    }
    30% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(5deg);
    }
    70% {
      transform: rotate(0deg);
    }
    80% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  
  @keyframes sonar {
    0% {
      transform: scale(0.9);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  body {
    font-size: 0.9rem;
    font-family: "Poppins", sans-serif;

  }
  .page-wrapper .sidebar-wrapper,
  .sidebar-wrapper .sidebar-brand > a,
  .sidebar-wrapper .sidebar-dropdown > a:after,
  .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
  .sidebar-wrapper ul li a i,
  .page-wrapper .page-content,
  .sidebar-wrapper .sidebar-search input.search-menu,
  .sidebar-wrapper .sidebar-search .input-group-text,
  .sidebar-wrapper .sidebar-menu ul li a,
  #show-sidebar,
  #close-sidebar {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  
  /*----------------page-wrapper----------------*/
  
  .page-wrapper {
    height: 100vh;
  }
  
  .page-wrapper .theme {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
    margin: 2px;
  }
  
  .page-wrapper .theme.chiller-theme {
    background: #291e1e;
  }
  
  /*----------------toggeled sidebar----------------*/
  
  .page-wrapper.toggled .sidebar-wrapper {
    left: 0px;
  }
  
  @media screen and (min-width: 768px) {
    .page-wrapper.toggled .page-content {
      padding-left: 260px;
    }
  
  }
  /*----------------show sidebar button----------------*/
  #show-sidebar {
    position: fixed;
    left: 20px;
    top: 18px;
    border-radius: 4px 4px 4px 4px;
    width: 35px;
    transition-delay: 0.3s;
    z-index: 11;
  }
  .page-wrapper.toggled #show-sidebar {
    left: -40px;
  }
  /*----------------sidebar-wrapper----------------*/
  
  .sidebar-wrapper {
    width: 270px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;
  }
  
  .sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar-wrapper a {
    text-decoration: none;
  }
  
  /*----------------sidebar-content----------------*/
  
  .sidebar-content {
    /* max-height: calc(100% - 30px); */
    height: calc(120% - 30px);
    position: relative;
  }
  
  .sidebar-content.desktop {
    overflow-y: hidden;
  }
  
  /*--------------------sidebar-brand----------------------*/
  
  .sidebar-wrapper .sidebar-brand {
    padding: 1px 20px;
    display: flex;
    align-items: center;
  }
  
  .sidebar-wrapper .sidebar-brand > a {
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
  }

 /***********************
 ** Start Sidebar Menu **
 ***********************/
 
 aside {
    position: fixed;
    width: 270px;
    height: 100%;
   
    overflow: auto;
 }
 
 
 
 .logo span span {
    color: rgb(25, 118, 210);
 }
 
 
 
 ul li label i:first-child {
    color: rgb(0, 0, 0);
 }
 
 /* Start Radio inputs */
 input[name="menu"],
 input[name=dropdowns]{
    display: none;
 }
 
 input[type=radio]:checked ~ label {
    color: rgb(0, 0, 0);
 }
 
 input[type=radio]:checked ~ label > i:first-child {
    color: rgb(0, 0, 0);
 }
 
 input:not(checked) ~ label {
    color: rgb(0, 0, 0);
 }
 /* End Radio inputs */
 
 
 /* Start Checkbox inputs */
 input[type=checkbox]:checked ~ ul {
    display: block;
 }
 input[type=checkbox] ~ :checked{
    display: none;
 }
 
 #uparrow,
 #downarrow {
    float: right;
 }
 
 input[name=dropdowns]:checked ~ label > #downarrow {
    display: none;
 }
 
 input[name=dropdowns]:checked ~ label > #uparrow {
    display: inline-block;
 }
 
 input[name=dropdowns]:not(checked) ~ label > #uparrow {
    display: none;
 }
 
 /* End Checkbox inputs */
 
 /* Start Menu */
 
.ul li {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    /* font-weight: 600; */
    margin-top: 18px;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;

 }
 
 ul li label i {
    width: 38px;
    margin-right: 5px;
    text-align: center;
 }
 
 ul li label {
    display: inline-block;
    width: 270px;
 }
 
 ul li label:hover {
    cursor: pointer;
 }
 
 .main {
    list-style-type: none;
    padding-left: 0;
 }
 
 .drop {
    display: none;
 }
 
 .drop li {
    display: block;
    font-size: 14px;
    padding-left: 20px;
 }
 
 .drop li label {
    width: 170px;
 }
 
 /* End Menu */
 
 /***********************
 ** End Sidebar Menu   **
 ***********************/
 
 nav {
  background: #ffffff;
  /* box-shadow: 0px 2px 50px rgb(0 0 0 / 20%); */
  /* color: black; */
  height: 70px;
 }
 input[type=checkbox] + label:before { display: none; } 


 /* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
  width: 5px;
} 
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.466); 
} 
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.788); 
}

.drops{
  height:180px;

  overflow-y: scroll;
}
.navbarss {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}





