

.chat_box{
	position:fixed;
	right:20px;
	bottom:0px;
	width:250px;
}
.chat_body{
  border: 1px solid rgba(184, 184, 184, 0.664);
	background:white;
	height:200px;

	overflow-y: scroll;
	padding:5px 0px;
}

.chat_head,.msg_head{
	background:rgb(255 0 0);
	color:white;
 


	font-weight:bold;
	cursor:pointer;
	border-radius:8px 8px 0px 0px;
    /* border: 1px solid gray; */

}
.spnas{
  font-size:11px;
  margin-left:35px;
 
}

.msg_box{
	position:fixed;
	bottom:-5px;
	width:250px;
	background:white;
	border-radius:5px 5px 0px 0px;
    border-bottom: 1px solid black
}



.close{
	float:right;
	cursor:pointer;
}
.minimize{
	float:right;
	cursor:pointer;
	padding-right:5px;
	
}

.user{
position:relative;
padding:3px 45px;
  /* margin-top:10px; */
  border-bottom:1px solid rgba(128, 128, 128, 0.226);

}
.user:hover{
	background:#e0d9d983;
	cursor:pointer;

}
.user:before{
	content:'';
	position:absolute;
	background:#039216d5;
	height:10px;
	width:10px;
	left:10px;
	top:15px;
	border-radius:6px;
}
.usera{
	
	padding-left:45px;
 	padding-top:10px;
	padding-bottom:10px;
	
}
.usera:before{
	content:'';
	position:absolute;
	background:#32960a;
	height:10px;
	width:10px;
	left:20px;
	top:18px;
	border-radius:6px;

}
