@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url("//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css");

body{
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  background-color: white;
}
.App {
  text-align: center;
}
.sidebar li
{
  display: block;
}

.maincard{
  color: white;
  margin-top: 2%;
}
label { font-size: 15px; }


/* Profile DropDown Sidebar1 */


/* @import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap'); */


.header__container {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(92, 92, 92, 0.199);
}
/* .logo {
  font-size: 20px;
  font-weight: 600;
  font-family: 'Festive', cursive;
} */
.nav__right {
  display: flex;
  align-items: center;
}
.user {
  border-radius: 100%;
  margin-right: 5px;
  
}
/* Profile DropDown */


.profile {
  position: relative;
  text-align: center;
  /* margin-top: 60px */
}

.profile dropbtn {
  width: 60px;
  /* height: 560px; */
  border-radius: 50%;
  
  cursor: pointer
}

.dropdown-content {
  display: none;
  color: black;
  /* justify-content: end; */
  position:absolute;
  background-color: #e7e7e7;
  min-width: 170%;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  z-index: 1;
  transition: all 2s;

}

.dropdown-content a {
  color: black;
  padding: 15px 18px;
  text-decoration: none;
  /* display: block */

}

.dropdown-content a:hover {
  background-color: rgb(173, 173, 173);
 
}

.dropdown:hover .dropdown-content 
{
  display: block;

}
/* =========-====================-======== */






table{
  width:100%;
  table-layout: fixed;
}
.tbl-header{
  background-color: rgba(180, 145, 145, 0.247);
  border: 1px solid rgba(0, 0, 255, 0.397);
 }
.tbl-content{
  height:360px;
  overflow-x:auto;
  margin-top: 0px;
  /* border: 1px solid rgb(184, 158, 158); */
  border: 1px solid rgba(0, 0, 255, 0.397);

}
th{
  padding: 20px 15px;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
}
td{
  /* padding: 15px; */
  text-align: left;
  vertical-align:middle;
  font-weight: 300;
  font-size: 12px;
  color: rgb(0, 0, 0);
  
  border-bottom: solid 1px rgba(100, 69, 69, 0.973);
}


/* demo styles */


section{
  margin: 30px;
}



/* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
    width: 10px;
} 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.466); 
} 
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.788); 
}





/* =========-====================-======== */

.profile ul {
  background-color: rgb(221, 221, 221);
  width: 270px;
  /* height: 100px; */
  border-radius: 10px;
  right: 20px;
  top: 8px;
  position: absolute;
  padding: 8px;
  transition: all 0.5s;
  z-index: 1;
  
  box-shadow: 10px 18px 15px rgba(71, 71, 71, 0.349);
}

.profile ul::before {
  content: '';
  position: absolute;
  background-color: #dddada;
  height: 10px;
  width: 10px;
  top: -5px;
  transform: rotate(45deg)
}

.profile ul li {
  list-style: none;
  text-align: center;
  font-size: 14px;
  padding: 8px;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  cursor: pointer;
  border-radius: 50px
}

.profile ul .hord:hover {
  background-color: rgba(253, 150, 150, 0.712);

}

.profile ul li i {
  margin-right: 4px
}

/* Profile DropDown End */

.my__accout {
  margin-right: 30px;
  margin-top: 8px;
color: tomato;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.my__accout span {
  /* margin-left: -4px; */
  font-family: 'Poppins',sans-serif;
  font-weight: 600;
  /* font-size: 15px; */
  /* color: rgb(151, 0, 0); */
}
.hovers1{
  font-family: 'Poppins',sans-serif;
  font-size: 12px;


}
.hovers1:hover {
 margin-left: 0px;
  padding: 8px;
  /* margin-left: -4px; */
  font-family: 'Poppins',sans-serif;
  font-weight: 600;
  font-size: 11px;
  background-color: rgb(49, 0, 0);
}
.group {
  position: relative;
}
.itemCound {
  position: absolute;
  top: -7px;
  right: -13px;
  font-size: 10px;
  width: 18px;
  height: 18px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  background: rgb(218, 54, 54);
  border-radius: 100px;
}

@media (min-width: 500px) {
  .header__container {
    padding: 0rem 3rem;
  }
}

@media (max-width: 500px) {
  .logo {
    font-size: 16px;
  }
}





/* DropDown sidebar1 END */


.disp{
  display: none;
}






/* button */
.spread-distance-box-shadow {
  box-shadow: 0 0 10px 5px;
  border: none;
  border-radius: 50px;
}
.box {
  width: 100%;
  text-align: center;
  margin: 20px auto;
  margin-bottom: 10px;
  /* background-color: #2d65ff; */
  color: rgba(128, 128, 128, 0.171);
  vertical-align: middle;
}
.box:hover{
  color: rgba(128, 128, 128, 0.288);
}
.size{
  font-size: 11px;
  text-transform: uppercase;
}
.hids{
  margin-right: 8%;
}
/* rOUTINE */
.radius{
  border-radius: 4px;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.03);
  padding: 8px;
}
.wid{
  width: 15%;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  margin-right: 3%;
}
.wids{
  color:white; 
  font-size: 150%;
  float: left ; 
  margin: 2.5% 1% ;
 margin-left: 1%;
 cursor: pointer;
}
.anchor{
  color: white;
  text-decoration: none;
}
.back2{
  background-image: url("../src/images/skylines.png");
  background-size: cover;
  height: 100vh;
  background-position: 50%;
}
.back3{
  background-image: url("../src/images/skylines.png");
  background-size: cover;
  height: 105vh;
  background-position: 50%;
}
.back5{
  background-image: url("../src/images/skylines.png");
  background-size: cover;
  height: 120vh;
  background-position: 90%;
  
}
.back6{
  background-image: url("../src/images/skylines.png");
  background-size: cover;
  height: 135vh;
  background-position: 90%;
  
}
.back7{
  background-image: url("../src/images/skylines.png");
  background-size: cover;
  height: 110%;
  background-position: 90%;
  
}
.back8{
  /* background: rgba( 255, 255, 255, 0.05 ); */
box-shadow: 0 8px 32px 0 rgba(201, 203, 233, 0.671);
backdrop-filter: blur( 0.5px );
-webkit-backdrop-filter: blur( 0.5px );
border-radius: 10px;
/* border: 1px solid rgba( 255, 255, 255, 0.18 ); */
}
.padd{
  padding-left: 15%;

}
.mar{
  margin-left: 9%;
}
/* insp */
.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
 width: 60%;
  border-radius: 5px;
  height: 220px;
}

.card:hover {
  box-shadow: 0 8px 50px 0 rgba(0,0,0,0.2);
}

.containers {
  padding: 2px 16px;
}
.list{
  list-style-type: "\⃞   ";
}

/* ------------------------- */
/* input[type=checkbox] { display:none; } 
input[type=checkbox] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

input[type=checkbox] + label:before { content: "\f096"; } 
input[type=checkbox] + label:before { letter-spacing: 10px; } 

input[type=checkbox]:checked + label:before { content: "\f046"; } 
input[type=checkbox]:checked + label:before { letter-spacing: 5px; } */

/*  ------------------- */
.cla{
 color: #c9ada7;
 margin-right: 39% ; 
 padding-bottom: 1%;
}
.clas1{
  margin-right: 40%;
  padding-bottom: 1%;
}
.clas3{
  margin-right: 30%;
  padding-bottom: 1%;
}
.clas2{
  margin-right: 62%; 
  padding-bottom: 1% ; 
}
.clas4{
  margin-right: 54%; 
  padding-bottom: 1% ; 
}
.clas5{
  margin-right: 58%; 
  padding-bottom: 1% ; 
}
.clas6{
  margin-right: 45%; 
  padding-bottom: 1% ; 
}
.clas7{
  margin-right: 49%; 
  padding-bottom: 1% ; 
}
.displayBlock{
  display: block;
}
.displayNone{
  display: none;
}

/* dashboard */
.font{
  font-family: 'Poppins', sans-serif;}
.back{
  background-image: url("../src/images/skylines.png");
  background-position: 2% ;
  background-size: cover;
}
.icon{
  color:white;
  background-color: red;
  padding:5px;
  font-size:40px;
  border-radius:50px;
  margin-right:10px; 
  padding-right:5px;
}
.icons{
  color:green;
  background-color: white;
  padding:5px;
  font-size:40px;
  border-radius:50px;
  margin-right:10px; 
  padding-right:5px;
}
.grad {
  /* background-image: linear-gradient(to right, #ac1909 , #d43523,#aa1909); */
  background-color: #707070;
}
.grad1 {
  background-image: linear-gradient(to right, #d11a06dc , #d43523d3,#d11a06dc);
}
.grad2 {
  /* background-image: linear-gradient(to right, #ac1909 , #d43523,#aa1909); */
  background-color: #009b1a;
}
.avatar {
  margin-right: 12%;
	width: 60px;
	height: 60px;
  border-radius: 50px; 
  /* margin: 10px; */
}
.flex-container {
  display: flex;
}
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}
.ic{
	display: inline-block;
	width: 17px;	
	height: 17px;	
	font-size: 17px;
	
	vertical-align: top;
	margin-top: calc((1.7 * 20px - 20px)/ 2);	
	margin-right: 30px;		
	position: relative;
}
.ic.outline{
  outline: solid 1px blue;
}


.ic.circle{
	border-radius: 100%;
	background-color: #FF5252;
}


.d-table {
  display:table;
}

.d-table-cell {
  display:table-cell;
}

.w-100 {
  width: 100%;
}

.tar {
  text-align: right;
}
.sizing{
  font-size: 1.2em;
}
/* login */
.inpFoc{
  border: 1px solid #d8e2dc;
  width: 70%;
    box-sizing: border-box;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none; 
}
.inpFoc:focus {
  border: 1px solid #6b705c;
}
.link_style{
  color: brown;
  text-decoration: none;
}
.link_style:hover{
  text-decoration: underline;
  color: brown;
}
.backgroundImg{
  background-image: url("./images/skylines.png"),
    url("./images/backLogin.png")
  ;
  background-size: cover;
  background-position: right 50% bottom 0%;
}
.backgroundImg1{
  background-image: url("./images/skylines.png"),
    url("./images/backLogin.png");
    height: 100vh;
  background-size: cover;
  background-position: right 50% bottom 0%;
  overflow-y: hidden;
}
.secback{
  background-image: url("./images/graphic.png");
  background-size: cover;
 
}


/* Navbar */

.wrapper .navbar{
  width: 100%;
  
  /* background: #fff; */
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  border-radius: 5px;
}


.wrapper .navbar .left ul,
.wrapper .navbar .right ul li button{
  display: flex;
  align-items: center;
  height: 60px;
}

.wrapper .navbar .left ul li,
.wrapper .navbar .right img{
  margin: 0 10px;
}


.wrapper .navbar .right button span{
  font-size: 15px;
}

.wrapper .navbar .right ul li{
  position: relative;
}

.wrapper .navbar .right ul li .dropdown{
  position: absolute;
  top: 75px;
  right: 56px;
  /* background: #fff; */
  padding: 10px 1px;
  border-radius: 5px;
  display: none;
}

.wrapper .navbar .right ul li .dropdown .fas{
  margin-right: 10px;
}

.wrapper .navbar .right ul li .dropdown:before{
  content: "";
  position: absolute;
  top: -20px;
  left: 70%;
  transform: translateX(-50%);
  border: 10px solid;
  border-color: transparent transparent #fff transparent;
}

.wrapper .navbar .right ul li:hover .dropdown{
  display: block;
}
.alignment{
  /* display: flex; */
  width: 200px;
  justify-content: flex-end;
}


.active{
  color: green;
}
.deactive{
  color: rgb(139, 139, 139);
}
.hovers{
  color: orange;
  background-color: white;
}
.hovers:hover{
  color: white;
  background-color: orange;
}
















@media only screen and (max-width: 650px) {
  .mar{
    padding-top: 10%;
  }
  .sizes{
    margin-top: 5%;
    font-size: 13px;
  }
  .hid{
    margin-left: 20%;
  }
  .wid{
    width: 20px;
    margin: 2%;
  }
  .wids{
    color:white; 
    font-size: 130%;
    float: left ; 
    margin-top: 7% ;
   margin-left: 3%;
   cursor: pointer;

  }
  .cla{
    margin-right: 0;
  }
  .clas1{
    margin-right: 0%;
  }
  .clas3{
    margin-right: 0%;
  }
  .clas2{
    margin-right: 0%;
  }
  .clas4{
    margin-right: 0%;
  }
  .clas5{
    margin-right: 0%;
  }
  .clas6{
    margin-right: 0%;
  }
  .clas7{
    margin-right: 0%;
  }
  .back3{
    height: 125vh;
  }
  .back5{
    height: 130vh;
  }
  .back6{
    height: 130vh;
  }
  .mar{
    margin-left: 17%;
    /* margin-right: 55%; */
  }

}

@media only screen and (min-width:768px) and (max-width: 1000px) {
.sizing{
  font-size: 8px;
}
}

@media only screen and (max-width: 800px) {
  
  .mar{
    padding-top: 20%;
  }
  .sizes{
    margin-top: 5%;
    font-size: 13px;
  }
  .hid{
    margin-left: 20%;
  }
  .wid{
    width: 45%;
    margin: 2%;
  }
  .wids{
    color:white; 
    font-size: 120%;
    float: left ; 
    margin-top: 7.5% ;
   margin-left: 3%;
   cursor: pointer;

  }
  .cla{
    margin-right: 0;
  }
  .clas1{
    margin-right: 0;
  }
  .clas3{
    margin-right: 0;
  }
  .clas2{
    margin-right: 0;
  }
  .clas4{
    margin-right: 0;
  }
  .clas5{
    margin-right: 0;
  }
  .clas6{
    margin-right: 0;
  }
  .clas7{
    margin-right: 0;
  }
  .back3{
    height: 125vh;
  }
  .back5{
    height: 110%;
  }
  .back6{
    height: 110%;
  }
  .mar{
    margin-left: 17%;
    /* margin-right: 55%; */
  }
  .ic{
    display: none;
  }
}
@media only screen and (min-width:1000px) and (max-width: 1500px) {
  .size{
    font-size: 9px;
  }
  .size1{
    font-size: 13px;
    padding-left: 5%;
  }
  .sizes{
    font-size: 13px;
  }
  .wid{
    width: 12%;
    margin: 2%;
  }
  .wids{
    color:white; 
    font-size: 30px;
    float: left ; 
    margin-top: 3% ;
   margin-left: 3%;
   cursor: pointer;

  }
  .cla{
    /* margin-right: s20%; */
    margin-right: 0%;
  }
  .clas1{
    /* margin-right: 20%; */
    margin-right: 0%;
  }
  .clas3{
    /* margin-right: 6%; */
    margin-right: 0%;
  }
  .clas4{
    /* margin-right: 6%; */
    margin-right: 0%;
  }
  .clas2{
    margin-right: 0%;
    /* margin-right: 55%; */
  }
  .clas5{
    margin-right: 0%;
    /* margin-right: 55%; */
  }
  .clas6{
    margin-right: 0%;
    /* margin-right: 55%; */
  }
  .clas7{
    margin-right: 0%;
    /* margin-right: 55%; */
  }
 .back6{
   height: 100vh;
 }
}



