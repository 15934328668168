body {
    margin:0px;
    padding:0px;
  }
  #heroo-image {
      top: 16px;
    height:150px;
    position: relative;
  }
  
  .covers {
    /* background: url('http://wallpaperget.com/images/cristiano-ronaldo-wallpaper-hd-39.jpg') no-repeat center center; */
    background-size: cover;
    height:150px;
    filter: blur(0px);
  }
  .profiles {
    position: absolute;
    top: 50px;    
    width:100%;  
    text-align: center;
    margin:0px;
  }
  .profiles-image {
    border: 5px solid #f8424283;
    width:170px;    
    height:170px;  
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
  }
  .profiles-image > img {
     max-width: 100%;
     transform: scale(1.8);
     position: relative;
     top: 30px;  
  }
  .profiles-details {
    color:#4F565A;
    margin-top:15px;
    letter-spacing: 1px;
  }
  .profiles-details > h1 {
    font-weight:600;
    font-size:32px;
    margin-bottom:5px;
    color: rgba(241, 21, 21, 0.979);
  }
  /* .profile-details > h3 {
    font-weight : 300;
    font-size: 30px;
    margin: 2px
  } */
  .profiles-details > p {
      font-size: 17px;
  
  }
  .overlayy {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
     
  }
  #contents-section {
    margin-top:300px;
  }
 
  .contents-details {
      font-size: 15px;
    color: rgb(150, 149, 149);
    margin-left: 10px;
    text-align: justify;
  }
 
 
  
  @media only screen and (max-width: 768px) {
    
    .contents-heading {
      text-align: left;
    }
    
    @media only screen and (width:991px) {
      .sporting {
        margin-top:-10px;
      }
    }
    
    
  }
  .list {
    list-style: none;
    font-size: 16px;
    margin:10px 0px 10px -20px;
    color:rgb(126, 126, 126)
    
  }
  .list > strong {
    color: rgba(0,0,0,0.8);
    font-weight: 700;
  }


  
