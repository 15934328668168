


.cd { 
    box-sizing: border-box;
     display: flex;
      align-items: center;
       justify-content: center;
        /* background-color: white; */
        z-index: 5000;
  position: relative;
  /* top: 30%;
  left: 42%; */
  padding: 1em;
  /* background-color: rgba(0, 0 , 0 ,0.2); */
  /* margin-left: auto;
  margin-right: auto; */
  top: 30%;
  left: auto;
  
  }
  
  
  
  .svg-calLoader {
     width: 200px;
      height: 200px;
    
    transform-origin: 115px 115px;
    animation: 1.4s linear infinite loader-spin;
  }
  
  .cal-loader__plane { 
    fill: rgb(90, 88, 88); 
  }
  
  .cal-loader__path {
     stroke: #ed1c23c0 ;
     animation: 1.4s ease-in-out infinite loader-path; 
    }
  
  @keyframes loader-spin {
    to{
      transform: rotate(360deg);
    }
  }
  @keyframes loader-path {
    0%{
      stroke-dasharray:  0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
    50%{
      stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
    }
    100%{
      stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
  }