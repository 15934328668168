body * { box-sizing: border-box;}



.containersa {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 100px;
}
.framea{
  box-shadow: 4px 4px 50px rgba(0,0,0,0.2);
width: 430px;
margin-left: auto;
margin-right: auto;

}

.frame{
  height: 600px;
  width: 430px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  transition: all .5s ease;
  border-bottom: solid 1px rgba(255,255,255, .5);

}
.frame-long {
  height: 450px;
}

/* .frame-short {
  height: 300px;
  margin-top: 50px;
} */

.navsa {

  width: 100%;
    /* height: 50px; */
    padding-top: 15px;
    opacity: 1;
    transition: all .5s ease;

}

.nav-up {
  transform: translateY(-100px);
  opacity: 0;
}

.listing {
  padding-left: 10px;
  font-size: 18px;
  display: inline;
  text-align: left;
  text-transform: uppercase;
  padding-right: 10px;
  color: #ffffff;
}

.signin-active .ands {
  padding-bottom: 10px;
  color: #000;
  text-decoration: none;
  border-bottom: solid 2px red;
  transition: all .25s ease;
  cursor: pointer;
}

.signin-inactive .ands {
  padding-bottom: 0;
  color: rgba(0, 0, 0, 0.493) ;
  text-decoration: none;
  border-bottom: none;
  cursor: pointer;
}

.signup-active .ands {
  cursor: pointer;
  color: #000;
  text-decoration: none;
  border-bottom: solid 2px red;
  padding-bottom: 10px;
}

.signup-inactive .ands {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.493);
  text-decoration: none;
  transition: all .25s ease;
}

.form-signin {
  width: 430px;
  height: 375px;
	font-size: 16px;
	font-weight: 300;
  padding-left: 37px;
  padding-right: 37px;
  padding-top: 25px;
  transition: opacity .5s ease, transform .5s ease;
}

.form-signin-left {
  transform: translateX(-400px);
  opacity: .0;
}

.form-signup {
  width: 430px;
  height: 375px;
	font-size: 16px;
	font-weight: 300;
  padding-left: 37px;
  padding-right: 37px;
  padding-top: 25px;
  position: relative;
  top: -375px;
  left: 400px;
  opacity: 0;
  transition: all .5s ease;
}

.form-signup-left {
  transform: translateX(-399px);
  opacity: 1;
}

.form-signup-down {
  top: 0px;
  opacity: 0;
}

.success {
  width: 80%;
  height: 150px;
  text-align: center;
  position: relative;
  top: -890px;
  left: 450px;
  opacity: .0;
  transition: all .8s .4s ease;
}

.success-left {
  transform: translateX(-406px);
  opacity: 1;
}

.successtext {
  color: #ffffff;
	font-size: 16px;
	font-weight: 300;
  margin-top: -35px;
  padding-left: 37px;
  padding-right: 37px;
}

#check path {
    stroke: #ffffff;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-width: .85px;
    stroke-dasharray: 60px 300px;
    stroke-dashoffset: -166px;
    fill: rgba(255,255,255,.0);
    transition: stroke-dashoffset 2s ease .5s, fill 1.5s ease 1.0s;
}

#check.checked path {
    stroke-dashoffset: 33px;
    fill: rgba(0, 0, 0, 0.03);
}

.form-signin .inputing, .form-signup .inputing {
  font-size: 13px;
}

.form-styling {
  width: 100%;
  height: 35px;
	padding-left: 15px;
	border: none;
	border-radius: 20px;
  margin-bottom: 20px;
  background: #80808045;
}

.labeling {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
  padding-left: 15px;
  padding-bottom: 10px;
  color: black;
  display: block;
}

:focus {outline: none;

}

.form-signin .inputing:focus{
  background: #8080801c;
    border: none; 
    padding-right: 40px;
    transition: background .5s ease;
    background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
 }

.inputes:not(:checked),
.inputes:checked {
  position: absolute;
  display: none;
}

.inputes:not(:checked) + .labeling,
.inputes:checked + .labeling {
  position: relative;
  padding-left: 85px;
  padding-top: 2px;
  cursor: pointer;
  color: #000;
  margin-top: 8px;
  letter-spacing: 1px;  
}

.inputes:not(:checked) + .labeling:before,
.inputes:checked + .labeling:before,
.inputes:not(:checked) + .labeling:after,
.inputes:checked + .labeling:after {
  content: '';
  position: absolute;
}

.inputes:not(:checked) + .labeling:before,
.inputes:checked + .labeling:before {
  width: 65px; 
  height: 30px;
  background: rgba(255,255,255,.2);
  border-radius: 15px;
  left: 0; 
  top: -3px;
  transition: all .2s ease;
}

.inputes:not(:checked) + .labeling:after,
.inputes:checked + .labeling:after {
  width: 10px; 
  height: 10px;
  background: rgba(128, 128, 128, 0.795);
  border-radius: 50%;
  top: 7px; 
  left: 10px;
  transition: all .2s ease;
}

/* on checked */
.inputes:checked + .labeling:before {
  background: #0F4FE6; 
}

.inputes:checked + .labeling:after {
  background: #f1223d;
  top: 7px; 
  left: 45px;
}

.inputes:checked + .labeling .ui,
.inputes:not(:checked) + .labeling .ui:before,
.inputes:checked + .labeling .ui:after {
  position: absolute;
  left: 6px;
  width: 65px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  transition: all .2s ease;
}

.inputes:not(:checked) + .labeling .ui:before {
  content: "no";
  left: 32px;
  color: rgba(128, 128, 128, 0.795);
}

.inputes:checked + .labeling .ui:after {
  content: "yes";
  color: #f1223d;
}

.inputes:focus + .labeling:before {
  box-sizing: border-box;
  margin-top: -1px;
}



.rem{
  
  width: 430px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0px 0px 5px 5px ;
  overflow: hidden;
  transition: all .5s ease;

}

.forgot {
  height: 100px;
  width: 330px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 24px;
  /* margin-top: 100px; */
  border-top: solid 1px rgb(192, 192, 192);
  transition: all 0.5s ease;
}

.reme{
  
  width: 430px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0px 0px 5px 5px ;
  overflow: hidden;
  transition: all .5s ease;

}

.forgote {
  height: 80px;
  width: 330px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* padding-bottom: 5px; */
  margin-bottom: 20px;
  border-bottom: solid 1px rgb(192, 192, 192);
  transition: all 0.5s ease;
}


.forgot a {
  color: black;
  font-weight: 400;
  font-size: 13px;
  text-decoration: none;
}
.forgote a {
  color: black;
  font-weight: 400;
  font-size: 13px;
  text-decoration: none;
}

.btons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 170px;
  height: 50px;
  padding: 0px 20px;
  background: transparent;
  color: #000;
 border:none;
  border-radius: 5px;
  text-decoration: none;
  overflow: hidden;
}

.btons span {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  color: #fff;  
  letter-spacing: 2px;
}

.btons::before {
  content: "";
  position: absolute;
  height: 40px;
  width: 210px;
  border-radius: 5px;
  background: rgb(237, 28, 36);
  z-index: -1;    
  left: 0;
  transition: all .5s ease 0s;
}


 .toggle-label {
  position: relative;
  display: block;
  width: 100px;
  height: 30px;
  margin-top: 8px;
  border: 1px solid #808080;
margin-left: 50px;
	border-radius: 15px;
}
.toggle-label .inpit { 
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
	
}
.toggle-label .inpit+.back {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ed1c24;
  transition: background 150ms linear; 
		border-radius: 50px;

}
.toggle-label .inpit:checked+.back {
  content: "ON";
  background: #00a651; /*green*/
	
}

.toggle1 {
  display: block;
  position: absolute;
  content: ' ';
  background: #fff;
  width: 55%; 
  height: 100%;
  transition: margin 150ms linear;
  border: 1px solid #808080;
	border-radius: 50px;
  left: 0;

}
.toggle-label .inpit:checked+.back .toggle1 {
  margin-left: 44px;
	
}
.toggle-label .label {
  display: block;
  position: absolute;
  width: 50%;
  color: #000;
  line-height: 30px;
  text-align: center;
  font-size: 0.8em;
  
	
}
.toggle-label .label.on { left: 0px; }
.toggle-label .label.off { right: 0px; }

.toggle-label .inpit:checked+.back .label.on {
  color: #fff;
}
.toggle-label .inpit:not(checked)+.back .label.off {
  color: #fff;
}
.toggle-label .inpit:checked+.back .label.off {
  color: #000;
}

.ads select {
  -moz-appearance:none;
  -webkit-appearance:none; 
  appearance:none;
}







select {

  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;


  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}


select.minimal {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}
select.minimal:focus {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}
















