@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Catamaran:400,800');
@import url('https://fonts.googleapis.com/css?family=Caveat');

.error-container {
  text-align: center;
  font-size: 106px;
  font-family: 'Catamaran', sans-serif;
  font-weight: 800;
  margin: 70px 15px;
}
.error-container > span {
  display: inline-block;
  position: relative;
}
.error-container > span.four {
  width: 136px;
  height: 43px;
  border-radius: 999px;
  background:
    linear-gradient(140deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 43%, transparent 44%, transparent 100%),
    linear-gradient(105deg, transparent 0%, transparent 40%, rgba(0, 0, 0, 0.06) 41%, rgba(0, 0, 0, 0.07) 76%, transparent 77%, transparent 100%),
    linear-gradient(to right, #f0213c, #f14b61, #CC9AA6, #f0213c);
}
.error-container > span.four:before,
.error-container > span.four:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 999px;
}
.error-container > span.four:before {
  width: 43px;
  height: 156px;
  left: 60px;
  bottom: -43px;
  background:
    linear-gradient(128deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 40%, transparent 41%, transparent 100%),
    linear-gradient(116deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 50%, transparent 51%, transparent 100%),
    linear-gradient(to top, #f0213c, #f14b61, #f18aa2, #f14b61, #f0213c);
}
.error-container > span.four:after {
  width: 137px;
  height: 43px;
  transform: rotate(-49.5deg);
  left: -18px;
  bottom: 36px;
  background: linear-gradient(to right, #f0213c, #f14b61, #CC9AA6, #f14b61, #f0213c);
}

.error-container > span.zero {
  vertical-align: text-top;
  width: 156px;
  height: 156px;
  border-radius: 999px;
  background: linear-gradient(-45deg, transparent 0%, rgba(0, 0, 0, 0.06) 50%,  transparent 51%, transparent 100%),
    linear-gradient(to top right, #f0213c,#f14b61 , #f74159bd, #CC9AA6, #f74159bd,#f14b61, #f0213c);
  overflow: hidden;
  animation: bgshadow 5s infinite;
}
.error-container > span.zero:before {
  content: '';
  display: block;
  position: absolute;
  transform: rotate(45deg);
  width: 90px;
  height: 90px;
  background-color: transparent;
  left: 0px;
  bottom: 0px;
  background:
    linear-gradient(95deg, transparent 0%, transparent 8%, rgba(0, 0, 0, 0.07) 9%, transparent 50%, transparent 100%),
    linear-gradient(85deg, transparent 0%, transparent 19%, rgba(0, 0, 0, 0.05) 20%, rgba(0, 0, 0, 0.07) 91%, transparent 92%, transparent 100%);
}
.error-container > span.zero:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 999px;
  width: 70px;
  height: 70px;
  left: 43px;
  bottom: 43px;
  background: #FDFAF5;
  box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.screen-reader-text {
    position: absolute;
    top: -9999em;
    left: -9999em;
}
    
@keyframes bgshadow {
  0% {
    box-shadow: inset -160px 160px 0px 5px rgba(233, 19, 19, 0.466);
  }
  45% {
    box-shadow: inset 0px 0px 0px 0px rgba(231, 30, 30, 0.507);
  }
  55% {
    box-shadow: inset 0px 0px 0px 0px rgba(223, 49, 49, 0.445);
  }
  100% {
    box-shadow: inset 160px -160px 0px 5px rgba(233, 19, 19, 0.466);
  }
}

/* demo stuff */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.h1s {
  text-align: center;
  margin: 30px 15px;
  color: red;
}
.zoom-area { 
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}
.anchor {
  font-family: 'Poppins', sans-serif;
  display: block;
  border: 1px solid rgba(255, 0, 0, 0.788);
  text-align: center;
  position: relative;
  cursor: pointer;
  width: 220px;
  padding: 9px;
  -webkit-transition: .55s;
  transition: .55s;
  color: black;
border-radius: 10px ;
margin-left: 44%;
}

.anchor:hover {
/* background-color: rgba(255, 0, 0, 0.788); */
border: none;
background:
linear-gradient(140deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 43%, transparent 44%, transparent 100%),
linear-gradient(105deg, transparent 0%, transparent 40%, rgba(0, 0, 0, 0.06) 41%, rgba(0, 0, 0, 0.07) 76%, transparent 77%, transparent 100%),
linear-gradient(to right, #f0213c,  rgba(233, 19, 19, 0.795), #f02453, #f0213c);
color: white;
}

.anchor:hover:after {
width: 0;
background-color: #ffffff;
}

.anchor:hover:before {
right: 10px;
border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}

.anchor:before, .anchor:after {
position: absolute;
  display: block;
  content: "";
  z-index: 2;
  -webkit-transition: .35s;
  transition: .35s;
}
.anchor:before {
    top: 17px;
  left: 30px;
  width: 7px;
  height: 7px;
  -webkit-transform: translateX(-10px) rotate(-45deg);
  transform: translateX(-10px) rotate(135deg);
  border-bottom: 1px solid #504b4b;
  border-right: 1px solid #504b4b;
}

.anchor:after {
  top: 19px;
  left: 25px;
  width: 35px;
  height: 1px;

  background-color: #504b4b;
  /* background-color: rgba(0, 0, 0, 0.74); */
}
.leyenda{
	font-family: 'Caveat', cursive;
	color: rgba(0, 0, 0, 0.712);
	font-size: 2.6em;
	margin-top: -0.9em;
  padding-bottom: 35px;
}