@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,800);
@import url(https://fonts.googleapis.com/css?family=Caveat);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  background-color: white;
}
.App {
  text-align: center;
}
.sidebar li
{
  display: block;
}

.maincard{
  color: white;
  margin-top: 2%;
}
label { font-size: 15px; }


/* Profile DropDown Sidebar1 */


/* @import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap'); */


.header__container {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(92, 92, 92, 0.199);
}
/* .logo {
  font-size: 20px;
  font-weight: 600;
  font-family: 'Festive', cursive;
} */
.nav__right {
  display: flex;
  align-items: center;
}
.user {
  border-radius: 100%;
  margin-right: 5px;
  
}
/* Profile DropDown */


.profile {
  position: relative;
  text-align: center;
  /* margin-top: 60px */
}

.profile dropbtn {
  width: 60px;
  /* height: 560px; */
  border-radius: 50%;
  
  cursor: pointer
}

.dropdown-content {
  display: none;
  color: black;
  /* justify-content: end; */
  position:absolute;
  background-color: #e7e7e7;
  min-width: 170%;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  z-index: 1;
  transition: all 2s;

}

.dropdown-content a {
  color: black;
  padding: 15px 18px;
  text-decoration: none;
  /* display: block */

}

.dropdown-content a:hover {
  background-color: rgb(173, 173, 173);
 
}

.dropdown:hover .dropdown-content 
{
  display: block;

}
/* =========-====================-======== */






table{
  width:100%;
  table-layout: fixed;
}
.tbl-header{
  background-color: rgba(180, 145, 145, 0.247);
  border: 1px solid rgba(0, 0, 255, 0.397);
 }
.tbl-content{
  height:360px;
  overflow-x:auto;
  margin-top: 0px;
  /* border: 1px solid rgb(184, 158, 158); */
  border: 1px solid rgba(0, 0, 255, 0.397);

}
th{
  padding: 20px 15px;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
}
td{
  /* padding: 15px; */
  text-align: left;
  vertical-align:middle;
  font-weight: 300;
  font-size: 12px;
  color: rgb(0, 0, 0);
  
  border-bottom: solid 1px rgba(100, 69, 69, 0.973);
}


/* demo styles */


section{
  margin: 30px;
}



/* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
    width: 10px;
} 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.466); 
} 
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.788); 
}





/* =========-====================-======== */

.profile ul {
  background-color: rgb(221, 221, 221);
  width: 270px;
  /* height: 100px; */
  border-radius: 10px;
  right: 20px;
  top: 8px;
  position: absolute;
  padding: 8px;
  transition: all 0.5s;
  z-index: 1;
  
  box-shadow: 10px 18px 15px rgba(71, 71, 71, 0.349);
}

.profile ul::before {
  content: '';
  position: absolute;
  background-color: #dddada;
  height: 10px;
  width: 10px;
  top: -5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg)
}

.profile ul li {
  list-style: none;
  text-align: center;
  font-size: 14px;
  padding: 8px;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  cursor: pointer;
  border-radius: 50px
}

.profile ul .hord:hover {
  background-color: rgba(253, 150, 150, 0.712);

}

.profile ul li i {
  margin-right: 4px
}

/* Profile DropDown End */

.my__accout {
  margin-right: 30px;
  margin-top: 8px;
color: tomato;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.my__accout span {
  /* margin-left: -4px; */
  font-family: 'Poppins',sans-serif;
  font-weight: 600;
  /* font-size: 15px; */
  /* color: rgb(151, 0, 0); */
}
.hovers1{
  font-family: 'Poppins',sans-serif;
  font-size: 12px;


}
.hovers1:hover {
 margin-left: 0px;
  padding: 8px;
  /* margin-left: -4px; */
  font-family: 'Poppins',sans-serif;
  font-weight: 600;
  font-size: 11px;
  background-color: rgb(49, 0, 0);
}
.group {
  position: relative;
}
.itemCound {
  position: absolute;
  top: -7px;
  right: -13px;
  font-size: 10px;
  width: 18px;
  height: 18px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  background: rgb(218, 54, 54);
  border-radius: 100px;
}

@media (min-width: 500px) {
  .header__container {
    padding: 0rem 3rem;
  }
}

@media (max-width: 500px) {
  .logo {
    font-size: 16px;
  }
}





/* DropDown sidebar1 END */


.disp{
  display: none;
}






/* button */
.spread-distance-box-shadow {
  box-shadow: 0 0 10px 5px;
  border: none;
  border-radius: 50px;
}
.box {
  width: 100%;
  text-align: center;
  margin: 20px auto;
  margin-bottom: 10px;
  /* background-color: #2d65ff; */
  color: rgba(128, 128, 128, 0.171);
  vertical-align: middle;
}
.box:hover{
  color: rgba(128, 128, 128, 0.288);
}
.size{
  font-size: 11px;
  text-transform: uppercase;
}
.hids{
  margin-right: 8%;
}
/* rOUTINE */
.radius{
  border-radius: 4px;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.03);
  padding: 8px;
}
.wid{
  width: 15%;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  margin-right: 3%;
}
.wids{
  color:white; 
  font-size: 150%;
  float: left ; 
  margin: 2.5% 1% ;
 margin-left: 1%;
 cursor: pointer;
}
.anchor{
  color: white;
  text-decoration: none;
}
.back2{
  background-image: url(/static/media/skylines.bb99527f.png);
  background-size: cover;
  height: 100vh;
  background-position: 50%;
}
.back3{
  background-image: url(/static/media/skylines.bb99527f.png);
  background-size: cover;
  height: 105vh;
  background-position: 50%;
}
.back5{
  background-image: url(/static/media/skylines.bb99527f.png);
  background-size: cover;
  height: 120vh;
  background-position: 90%;
  
}
.back6{
  background-image: url(/static/media/skylines.bb99527f.png);
  background-size: cover;
  height: 135vh;
  background-position: 90%;
  
}
.back7{
  background-image: url(/static/media/skylines.bb99527f.png);
  background-size: cover;
  height: 110%;
  background-position: 90%;
  
}
.back8{
  /* background: rgba( 255, 255, 255, 0.05 ); */
box-shadow: 0 8px 32px 0 rgba(201, 203, 233, 0.671);
backdrop-filter: blur( 0.5px );
-webkit-backdrop-filter: blur( 0.5px );
border-radius: 10px;
/* border: 1px solid rgba( 255, 255, 255, 0.18 ); */
}
.padd{
  padding-left: 15%;

}
.mar{
  margin-left: 9%;
}
/* insp */
.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
 width: 60%;
  border-radius: 5px;
  height: 220px;
}

.card:hover {
  box-shadow: 0 8px 50px 0 rgba(0,0,0,0.2);
}

.containers {
  padding: 2px 16px;
}
.list{
  list-style-type: "\⃞   ";
}

/* ------------------------- */
/* input[type=checkbox] { display:none; } 
input[type=checkbox] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

input[type=checkbox] + label:before { content: "\f096"; } 
input[type=checkbox] + label:before { letter-spacing: 10px; } 

input[type=checkbox]:checked + label:before { content: "\f046"; } 
input[type=checkbox]:checked + label:before { letter-spacing: 5px; } */

/*  ------------------- */
.cla{
 color: #c9ada7;
 margin-right: 39% ; 
 padding-bottom: 1%;
}
.clas1{
  margin-right: 40%;
  padding-bottom: 1%;
}
.clas3{
  margin-right: 30%;
  padding-bottom: 1%;
}
.clas2{
  margin-right: 62%; 
  padding-bottom: 1% ; 
}
.clas4{
  margin-right: 54%; 
  padding-bottom: 1% ; 
}
.clas5{
  margin-right: 58%; 
  padding-bottom: 1% ; 
}
.clas6{
  margin-right: 45%; 
  padding-bottom: 1% ; 
}
.clas7{
  margin-right: 49%; 
  padding-bottom: 1% ; 
}
.displayBlock{
  display: block;
}
.displayNone{
  display: none;
}

/* dashboard */
.font{
  font-family: 'Poppins', sans-serif;}
.back{
  background-image: url(/static/media/skylines.bb99527f.png);
  background-position: 2% ;
  background-size: cover;
}
.icon{
  color:white;
  background-color: red;
  padding:5px;
  font-size:40px;
  border-radius:50px;
  margin-right:10px; 
  padding-right:5px;
}
.icons{
  color:green;
  background-color: white;
  padding:5px;
  font-size:40px;
  border-radius:50px;
  margin-right:10px; 
  padding-right:5px;
}
.grad {
  /* background-image: linear-gradient(to right, #ac1909 , #d43523,#aa1909); */
  background-color: #707070;
}
.grad1 {
  background-image: linear-gradient(to right, #d11a06dc , #d43523d3,#d11a06dc);
}
.grad2 {
  /* background-image: linear-gradient(to right, #ac1909 , #d43523,#aa1909); */
  background-color: #009b1a;
}
.avatar {
  margin-right: 12%;
	width: 60px;
	height: 60px;
  border-radius: 50px; 
  /* margin: 10px; */
}
.flex-container {
  display: flex;
}
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}
.ic{
	display: inline-block;
	width: 17px;	
	height: 17px;	
	font-size: 17px;
	
	vertical-align: top;
	margin-top: calc((1.7 * 20px - 20px)/ 2);	
	margin-right: 30px;		
	position: relative;
}
.ic.outline{
  outline: solid 1px blue;
}


.ic.circle{
	border-radius: 100%;
	background-color: #FF5252;
}


.d-table {
  display:table;
}

.d-table-cell {
  display:table-cell;
}

.w-100 {
  width: 100%;
}

.tar {
  text-align: right;
}
.sizing{
  font-size: 1.2em;
}
/* login */
.inpFoc{
  border: 1px solid #d8e2dc;
  width: 70%;
    box-sizing: border-box;
    transition: 0.5s;
    outline: none; 
}
.inpFoc:focus {
  border: 1px solid #6b705c;
}
.link_style{
  color: brown;
  text-decoration: none;
}
.link_style:hover{
  text-decoration: underline;
  color: brown;
}
.backgroundImg{
  background-image: url(/static/media/skylines.bb99527f.png),
    url(/static/media/backLogin.83e3d53f.png);
  background-size: cover;
  background-position: right 50% bottom 0%;
}
.backgroundImg1{
  background-image: url(/static/media/skylines.bb99527f.png),
    url(/static/media/backLogin.83e3d53f.png);
    height: 100vh;
  background-size: cover;
  background-position: right 50% bottom 0%;
  overflow-y: hidden;
}
.secback{
  background-image: url(/static/media/graphic.79cef968.png);
  background-size: cover;
 
}


/* Navbar */

.wrapper .navbar{
  width: 100%;
  
  /* background: #fff; */
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  border-radius: 5px;
}


.wrapper .navbar .left ul,
.wrapper .navbar .right ul li button{
  display: flex;
  align-items: center;
  height: 60px;
}

.wrapper .navbar .left ul li,
.wrapper .navbar .right img{
  margin: 0 10px;
}


.wrapper .navbar .right button span{
  font-size: 15px;
}

.wrapper .navbar .right ul li{
  position: relative;
}

.wrapper .navbar .right ul li .dropdown{
  position: absolute;
  top: 75px;
  right: 56px;
  /* background: #fff; */
  padding: 10px 1px;
  border-radius: 5px;
  display: none;
}

.wrapper .navbar .right ul li .dropdown .fas{
  margin-right: 10px;
}

.wrapper .navbar .right ul li .dropdown:before{
  content: "";
  position: absolute;
  top: -20px;
  left: 70%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border: 10px solid;
  border-color: transparent transparent #fff transparent;
}

.wrapper .navbar .right ul li:hover .dropdown{
  display: block;
}
.alignment{
  /* display: flex; */
  width: 200px;
  justify-content: flex-end;
}


.active{
  color: green;
}
.deactive{
  color: rgb(139, 139, 139);
}
.hovers{
  color: orange;
  background-color: white;
}
.hovers:hover{
  color: white;
  background-color: orange;
}
















@media only screen and (max-width: 650px) {
  .mar{
    padding-top: 10%;
  }
  .sizes{
    margin-top: 5%;
    font-size: 13px;
  }
  .hid{
    margin-left: 20%;
  }
  .wid{
    width: 20px;
    margin: 2%;
  }
  .wids{
    color:white; 
    font-size: 130%;
    float: left ; 
    margin-top: 7% ;
   margin-left: 3%;
   cursor: pointer;

  }
  .cla{
    margin-right: 0;
  }
  .clas1{
    margin-right: 0%;
  }
  .clas3{
    margin-right: 0%;
  }
  .clas2{
    margin-right: 0%;
  }
  .clas4{
    margin-right: 0%;
  }
  .clas5{
    margin-right: 0%;
  }
  .clas6{
    margin-right: 0%;
  }
  .clas7{
    margin-right: 0%;
  }
  .back3{
    height: 125vh;
  }
  .back5{
    height: 130vh;
  }
  .back6{
    height: 130vh;
  }
  .mar{
    margin-left: 17%;
    /* margin-right: 55%; */
  }

}

@media only screen and (min-width:768px) and (max-width: 1000px) {
.sizing{
  font-size: 8px;
}
}

@media only screen and (max-width: 800px) {
  
  .mar{
    padding-top: 20%;
  }
  .sizes{
    margin-top: 5%;
    font-size: 13px;
  }
  .hid{
    margin-left: 20%;
  }
  .wid{
    width: 45%;
    margin: 2%;
  }
  .wids{
    color:white; 
    font-size: 120%;
    float: left ; 
    margin-top: 7.5% ;
   margin-left: 3%;
   cursor: pointer;

  }
  .cla{
    margin-right: 0;
  }
  .clas1{
    margin-right: 0;
  }
  .clas3{
    margin-right: 0;
  }
  .clas2{
    margin-right: 0;
  }
  .clas4{
    margin-right: 0;
  }
  .clas5{
    margin-right: 0;
  }
  .clas6{
    margin-right: 0;
  }
  .clas7{
    margin-right: 0;
  }
  .back3{
    height: 125vh;
  }
  .back5{
    height: 110%;
  }
  .back6{
    height: 110%;
  }
  .mar{
    margin-left: 17%;
    /* margin-right: 55%; */
  }
  .ic{
    display: none;
  }
}
@media only screen and (min-width:1000px) and (max-width: 1500px) {
  .size{
    font-size: 9px;
  }
  .size1{
    font-size: 13px;
    padding-left: 5%;
  }
  .sizes{
    font-size: 13px;
  }
  .wid{
    width: 12%;
    margin: 2%;
  }
  .wids{
    color:white; 
    font-size: 30px;
    float: left ; 
    margin-top: 3% ;
   margin-left: 3%;
   cursor: pointer;

  }
  .cla{
    /* margin-right: s20%; */
    margin-right: 0%;
  }
  .clas1{
    /* margin-right: 20%; */
    margin-right: 0%;
  }
  .clas3{
    /* margin-right: 6%; */
    margin-right: 0%;
  }
  .clas4{
    /* margin-right: 6%; */
    margin-right: 0%;
  }
  .clas2{
    margin-right: 0%;
    /* margin-right: 55%; */
  }
  .clas5{
    margin-right: 0%;
    /* margin-right: 55%; */
  }
  .clas6{
    margin-right: 0%;
    /* margin-right: 55%; */
  }
  .clas7{
    margin-right: 0%;
    /* margin-right: 55%; */
  }
 .back6{
   height: 100vh;
 }
}




body * { box-sizing: border-box;}



.containersa {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 100px;
}
.framea{
  box-shadow: 4px 4px 50px rgba(0,0,0,0.2);
width: 430px;
margin-left: auto;
margin-right: auto;

}

.frame{
  height: 600px;
  width: 430px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  transition: all .5s ease;
  border-bottom: solid 1px rgba(255,255,255, .5);

}
.frame-long {
  height: 450px;
}

/* .frame-short {
  height: 300px;
  margin-top: 50px;
} */

.navsa {

  width: 100%;
    /* height: 50px; */
    padding-top: 15px;
    opacity: 1;
    transition: all .5s ease;

}

.nav-up {
  -webkit-transform: translateY(-100px);
          transform: translateY(-100px);
  opacity: 0;
}

.listing {
  padding-left: 10px;
  font-size: 18px;
  display: inline;
  text-align: left;
  text-transform: uppercase;
  padding-right: 10px;
  color: #ffffff;
}

.signin-active .ands {
  padding-bottom: 10px;
  color: #000;
  text-decoration: none;
  border-bottom: solid 2px red;
  transition: all .25s ease;
  cursor: pointer;
}

.signin-inactive .ands {
  padding-bottom: 0;
  color: rgba(0, 0, 0, 0.493) ;
  text-decoration: none;
  border-bottom: none;
  cursor: pointer;
}

.signup-active .ands {
  cursor: pointer;
  color: #000;
  text-decoration: none;
  border-bottom: solid 2px red;
  padding-bottom: 10px;
}

.signup-inactive .ands {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.493);
  text-decoration: none;
  transition: all .25s ease;
}

.form-signin {
  width: 430px;
  height: 375px;
	font-size: 16px;
	font-weight: 300;
  padding-left: 37px;
  padding-right: 37px;
  padding-top: 25px;
  transition: opacity .5s ease, -webkit-transform .5s ease;
  transition: opacity .5s ease, transform .5s ease;
  transition: opacity .5s ease, transform .5s ease, -webkit-transform .5s ease;
}

.form-signin-left {
  -webkit-transform: translateX(-400px);
          transform: translateX(-400px);
  opacity: .0;
}

.form-signup {
  width: 430px;
  height: 375px;
	font-size: 16px;
	font-weight: 300;
  padding-left: 37px;
  padding-right: 37px;
  padding-top: 25px;
  position: relative;
  top: -375px;
  left: 400px;
  opacity: 0;
  transition: all .5s ease;
}

.form-signup-left {
  -webkit-transform: translateX(-399px);
          transform: translateX(-399px);
  opacity: 1;
}

.form-signup-down {
  top: 0px;
  opacity: 0;
}

.success {
  width: 80%;
  height: 150px;
  text-align: center;
  position: relative;
  top: -890px;
  left: 450px;
  opacity: .0;
  transition: all .8s .4s ease;
}

.success-left {
  -webkit-transform: translateX(-406px);
          transform: translateX(-406px);
  opacity: 1;
}

.successtext {
  color: #ffffff;
	font-size: 16px;
	font-weight: 300;
  margin-top: -35px;
  padding-left: 37px;
  padding-right: 37px;
}

#check path {
    stroke: #ffffff;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-width: .85px;
    stroke-dasharray: 60px 300px;
    stroke-dashoffset: -166px;
    fill: rgba(255,255,255,.0);
    transition: stroke-dashoffset 2s ease .5s, fill 1.5s ease 1.0s;
}

#check.checked path {
    stroke-dashoffset: 33px;
    fill: rgba(0, 0, 0, 0.03);
}

.form-signin .inputing, .form-signup .inputing {
  font-size: 13px;
}

.form-styling {
  width: 100%;
  height: 35px;
	padding-left: 15px;
	border: none;
	border-radius: 20px;
  margin-bottom: 20px;
  background: #80808045;
}

.labeling {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
  padding-left: 15px;
  padding-bottom: 10px;
  color: black;
  display: block;
}

:focus {outline: none;

}

.form-signin .inputing:focus{
  background: #8080801c;
    border: none; 
    padding-right: 40px;
    transition: background .5s ease;
    background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
 }

.inputes:not(:checked),
.inputes:checked {
  position: absolute;
  display: none;
}

.inputes:not(:checked) + .labeling,
.inputes:checked + .labeling {
  position: relative;
  padding-left: 85px;
  padding-top: 2px;
  cursor: pointer;
  color: #000;
  margin-top: 8px;
  letter-spacing: 1px;  
}

.inputes:not(:checked) + .labeling:before,
.inputes:checked + .labeling:before,
.inputes:not(:checked) + .labeling:after,
.inputes:checked + .labeling:after {
  content: '';
  position: absolute;
}

.inputes:not(:checked) + .labeling:before,
.inputes:checked + .labeling:before {
  width: 65px; 
  height: 30px;
  background: rgba(255,255,255,.2);
  border-radius: 15px;
  left: 0; 
  top: -3px;
  transition: all .2s ease;
}

.inputes:not(:checked) + .labeling:after,
.inputes:checked + .labeling:after {
  width: 10px; 
  height: 10px;
  background: rgba(128, 128, 128, 0.795);
  border-radius: 50%;
  top: 7px; 
  left: 10px;
  transition: all .2s ease;
}

/* on checked */
.inputes:checked + .labeling:before {
  background: #0F4FE6; 
}

.inputes:checked + .labeling:after {
  background: #f1223d;
  top: 7px; 
  left: 45px;
}

.inputes:checked + .labeling .ui,
.inputes:not(:checked) + .labeling .ui:before,
.inputes:checked + .labeling .ui:after {
  position: absolute;
  left: 6px;
  width: 65px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  transition: all .2s ease;
}

.inputes:not(:checked) + .labeling .ui:before {
  content: "no";
  left: 32px;
  color: rgba(128, 128, 128, 0.795);
}

.inputes:checked + .labeling .ui:after {
  content: "yes";
  color: #f1223d;
}

.inputes:focus + .labeling:before {
  box-sizing: border-box;
  margin-top: -1px;
}



.rem{
  
  width: 430px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0px 0px 5px 5px ;
  overflow: hidden;
  transition: all .5s ease;

}

.forgot {
  height: 100px;
  width: 330px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 24px;
  /* margin-top: 100px; */
  border-top: solid 1px rgb(192, 192, 192);
  transition: all 0.5s ease;
}

.reme{
  
  width: 430px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0px 0px 5px 5px ;
  overflow: hidden;
  transition: all .5s ease;

}

.forgote {
  height: 80px;
  width: 330px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* padding-bottom: 5px; */
  margin-bottom: 20px;
  border-bottom: solid 1px rgb(192, 192, 192);
  transition: all 0.5s ease;
}


.forgot a {
  color: black;
  font-weight: 400;
  font-size: 13px;
  text-decoration: none;
}
.forgote a {
  color: black;
  font-weight: 400;
  font-size: 13px;
  text-decoration: none;
}

.btons {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 170px;
  height: 50px;
  padding: 0px 20px;
  background: transparent;
  color: #000;
 border:none;
  border-radius: 5px;
  text-decoration: none;
  overflow: hidden;
}

.btons span {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  color: #fff;  
  letter-spacing: 2px;
}

.btons::before {
  content: "";
  position: absolute;
  height: 40px;
  width: 210px;
  border-radius: 5px;
  background: rgb(237, 28, 36);
  z-index: -1;    
  left: 0;
  transition: all .5s ease 0s;
}


 .toggle-label {
  position: relative;
  display: block;
  width: 100px;
  height: 30px;
  margin-top: 8px;
  border: 1px solid #808080;
margin-left: 50px;
	border-radius: 15px;
}
.toggle-label .inpit { 
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
	
}
.toggle-label .inpit+.back {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ed1c24;
  transition: background 150ms linear; 
		border-radius: 50px;

}
.toggle-label .inpit:checked+.back {
  content: "ON";
  background: #00a651; /*green*/
	
}

.toggle1 {
  display: block;
  position: absolute;
  content: ' ';
  background: #fff;
  width: 55%; 
  height: 100%;
  transition: margin 150ms linear;
  border: 1px solid #808080;
	border-radius: 50px;
  left: 0;

}
.toggle-label .inpit:checked+.back .toggle1 {
  margin-left: 44px;
	
}
.toggle-label .label {
  display: block;
  position: absolute;
  width: 50%;
  color: #000;
  line-height: 30px;
  text-align: center;
  font-size: 0.8em;
  
	
}
.toggle-label .label.on { left: 0px; }
.toggle-label .label.off { right: 0px; }

.toggle-label .inpit:checked+.back .label.on {
  color: #fff;
}
.toggle-label .inpit:not(checked)+.back .label.off {
  color: #fff;
}
.toggle-label .inpit:checked+.back .label.off {
  color: #000;
}

.ads select {
  -webkit-appearance:none; 
  appearance:none;
}







select {

  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;


  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}


select.minimal {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}
select.minimal:focus {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

















@-webkit-keyframes swing {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    10% {
      -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
    }
    30% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    40% {
      -webkit-transform: rotate(-10deg);
              transform: rotate(-10deg);
    }
    50% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    60% {
      -webkit-transform: rotate(5deg);
              transform: rotate(5deg);
    }
    70% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    80% {
      -webkit-transform: rotate(-5deg);
              transform: rotate(-5deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }





@keyframes swing {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    10% {
      -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
    }
    30% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    40% {
      -webkit-transform: rotate(-10deg);
              transform: rotate(-10deg);
    }
    50% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    60% {
      -webkit-transform: rotate(5deg);
              transform: rotate(5deg);
    }
    70% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    80% {
      -webkit-transform: rotate(-5deg);
              transform: rotate(-5deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }
  
  @-webkit-keyframes sonar {
    0% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
              transform: scale(2);
      opacity: 0;
    }
  }
  
  @keyframes sonar {
    0% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
              transform: scale(2);
      opacity: 0;
    }
  }
  body {
    font-size: 0.9rem;
    font-family: "Poppins", sans-serif;

  }
  .page-wrapper .sidebar-wrapper,
  .sidebar-wrapper .sidebar-brand > a,
  .sidebar-wrapper .sidebar-dropdown > a:after,
  .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
  .sidebar-wrapper ul li a i,
  .page-wrapper .page-content,
  .sidebar-wrapper .sidebar-search input.search-menu,
  .sidebar-wrapper .sidebar-search .input-group-text,
  .sidebar-wrapper .sidebar-menu ul li a,
  #show-sidebar,
  #close-sidebar {
    transition: all 0.3s ease;
  }
  
  /*----------------page-wrapper----------------*/
  
  .page-wrapper {
    height: 100vh;
  }
  
  .page-wrapper .theme {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
    margin: 2px;
  }
  
  .page-wrapper .theme.chiller-theme {
    background: #291e1e;
  }
  
  /*----------------toggeled sidebar----------------*/
  
  .page-wrapper.toggled .sidebar-wrapper {
    left: 0px;
  }
  
  @media screen and (min-width: 768px) {
    .page-wrapper.toggled .page-content {
      padding-left: 260px;
    }
  
  }
  /*----------------show sidebar button----------------*/
  #show-sidebar {
    position: fixed;
    left: 20px;
    top: 18px;
    border-radius: 4px 4px 4px 4px;
    width: 35px;
    transition-delay: 0.3s;
    z-index: 11;
  }
  .page-wrapper.toggled #show-sidebar {
    left: -40px;
  }
  /*----------------sidebar-wrapper----------------*/
  
  .sidebar-wrapper {
    width: 270px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;
  }
  
  .sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar-wrapper a {
    text-decoration: none;
  }
  
  /*----------------sidebar-content----------------*/
  
  .sidebar-content {
    /* max-height: calc(100% - 30px); */
    height: calc(120% - 30px);
    position: relative;
  }
  
  .sidebar-content.desktop {
    overflow-y: hidden;
  }
  
  /*--------------------sidebar-brand----------------------*/
  
  .sidebar-wrapper .sidebar-brand {
    padding: 1px 20px;
    display: flex;
    align-items: center;
  }
  
  .sidebar-wrapper .sidebar-brand > a {
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
  }

 /***********************
 ** Start Sidebar Menu **
 ***********************/
 
 aside {
    position: fixed;
    width: 270px;
    height: 100%;
   
    overflow: auto;
 }
 
 
 
 .logo span span {
    color: rgb(25, 118, 210);
 }
 
 
 
 ul li label i:first-child {
    color: rgb(0, 0, 0);
 }
 
 /* Start Radio inputs */
 input[name="menu"],
 input[name=dropdowns]{
    display: none;
 }
 
 input[type=radio]:checked ~ label {
    color: rgb(0, 0, 0);
 }
 
 input[type=radio]:checked ~ label > i:first-child {
    color: rgb(0, 0, 0);
 }
 
 input:not(checked) ~ label {
    color: rgb(0, 0, 0);
 }
 /* End Radio inputs */
 
 
 /* Start Checkbox inputs */
 input[type=checkbox]:checked ~ ul {
    display: block;
 }
 input[type=checkbox] ~ :checked{
    display: none;
 }
 
 #uparrow,
 #downarrow {
    float: right;
 }
 
 input[name=dropdowns]:checked ~ label > #downarrow {
    display: none;
 }
 
 input[name=dropdowns]:checked ~ label > #uparrow {
    display: inline-block;
 }
 
 input[name=dropdowns]:not(checked) ~ label > #uparrow {
    display: none;
 }
 
 /* End Checkbox inputs */
 
 /* Start Menu */
 
.ul li {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    /* font-weight: 600; */
    margin-top: 18px;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;

 }
 
 ul li label i {
    width: 38px;
    margin-right: 5px;
    text-align: center;
 }
 
 ul li label {
    display: inline-block;
    width: 270px;
 }
 
 ul li label:hover {
    cursor: pointer;
 }
 
 .main {
    list-style-type: none;
    padding-left: 0;
 }
 
 .drop {
    display: none;
 }
 
 .drop li {
    display: block;
    font-size: 14px;
    padding-left: 20px;
 }
 
 .drop li label {
    width: 170px;
 }
 
 /* End Menu */
 
 /***********************
 ** End Sidebar Menu   **
 ***********************/
 
 nav {
  background: #ffffff;
  /* box-shadow: 0px 2px 50px rgb(0 0 0 / 20%); */
  /* color: black; */
  height: 70px;
 }
 input[type=checkbox] + label:before { display: none; } 


 /* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
  width: 5px;
} 
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.466); 
} 
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.788); 
}

.drops{
  height:180px;

  overflow-y: scroll;
}
.navbarss {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}






.read-more-state {
	display: none;
  }
  
  .read-more-target {
	opacity: 0;
	max-height: 0;
	font-size: 0;
	transition: .5s ease;
  }
  
  .read-more-state:checked ~ .read-more-wrap .read-more-target {
	opacity: 1;
	font-size: inherit;
	max-height: 999em;
  }
  
  /* .read-more-trigger:before {
	content: 'Show more';
  }
  
  .read-more-trigger {
	content: 'Show less';
  } */
  
  .read-more-trigger {
	cursor: pointer;
	display: inline-block;
	padding: 0 .5em;
	color: #666;
	font-size: .9em;
	line-height: 2;
	/* border: 1px solid #ddd; */
	border-radius: .25em;
  }
  
  


.chat_box{
	position:fixed;
	right:20px;
	bottom:0px;
	width:250px;
}
.chat_body{
  border: 1px solid rgba(184, 184, 184, 0.664);
	background:white;
	height:200px;

	overflow-y: scroll;
	padding:5px 0px;
}

.chat_head,.msg_head{
	background:rgb(255 0 0);
	color:white;
 


	font-weight:bold;
	cursor:pointer;
	border-radius:8px 8px 0px 0px;
    /* border: 1px solid gray; */

}
.spnas{
  font-size:11px;
  margin-left:35px;
 
}

.msg_box{
	position:fixed;
	bottom:-5px;
	width:250px;
	background:white;
	border-radius:5px 5px 0px 0px;
    border-bottom: 1px solid black
}



.close{
	float:right;
	cursor:pointer;
}
.minimize{
	float:right;
	cursor:pointer;
	padding-right:5px;
	
}

.user{
position:relative;
padding:3px 45px;
  /* margin-top:10px; */
  border-bottom:1px solid rgba(128, 128, 128, 0.226);

}
.user:hover{
	background:#e0d9d983;
	cursor:pointer;

}
.user:before{
	content:'';
	position:absolute;
	background:#039216d5;
	height:10px;
	width:10px;
	left:10px;
	top:15px;
	border-radius:6px;
}
.usera{
	
	padding-left:45px;
 	padding-top:10px;
	padding-bottom:10px;
	
}
.usera:before{
	content:'';
	position:absolute;
	background:#32960a;
	height:10px;
	width:10px;
	left:20px;
	top:18px;
	border-radius:6px;

}

*,*::before,*::after{box-sizing:border-box;margin:0;padding:0}.thisTable{border-collapse:collapse;box-shadow:0 5px 10px #e1e5ee;background-color:#fff;overflow:auto}.thisTable thead{box-shadow:0 5px 10px #e1e5ee}.thisTable th{text-transform:uppercase;font-weight:900;border:1px solid rgba(53,52,52,.548)}.thisTable td{border:1px solid #fff}.thisTable .paid{background-color:rgba(207,193,193,.26);color:red}.thisTable .paide{background-color:rgba(240,44,44,.829);color:#fff;border:1px solid rgba(240,44,44,.658)}.thisTable .unpaid{background-color:#adadad13}.thisTable .status{border-radius:.2rem;padding:.5rem 1rem;text-align:center}.thisTable .status-pending{background-color:#fff0c2;color:#a68b00}.thisTable .status-paid{background-color:#fd6e6e;color:#fcfcfc}.thisTable .status-unpaid{background-color:#adadad13;color:red;border:1px solid rgba(175,139,139,.658)}.thisTable .statusa{border-radius:.2rem;padding:.5rem .5rem;text-align:center}.thisTable .statusa-pending{background-color:#fff0c2;color:#a68b00}.thisTable .statusa-paid{background-color:#fd6e6e;color:#fcfcfc}.thisTable .amount{text-align:right}:root{--link-1: rgb(221, 51, 51);--text: #161515}.anchs{text-decoration:none;color:#161515;color:var(--text);font-weight:700;vertical-align:top}#style-2{position:relative;transition:color .3s ease-in-out}#style-2::before{content:"";position:absolute;top:100%;width:100%;height:3.5px;background-color:rgb(221, 51, 51);background-color:var(--link-1);-webkit-transform:scaleX(0);transform:scaleX(0);transition:-webkit-transform .4s ease-in-out;transition:transform .4s ease-in-out;transition:transform .4s ease-in-out, -webkit-transform .4s ease-in-out}#style-2:hover{color:rgb(221, 51, 51);color:var(--link-1)}#style-2:hover::before{-webkit-transform:scaleX(1);transform:scaleX(1)}
body {
    margin:0px;
    padding:0px;
  }
  #heroo-image {
      top: 16px;
    height:150px;
    position: relative;
  }
  
  .covers {
    /* background: url('http://wallpaperget.com/images/cristiano-ronaldo-wallpaper-hd-39.jpg') no-repeat center center; */
    background-size: cover;
    height:150px;
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
  .profiles {
    position: absolute;
    top: 50px;    
    width:100%;  
    text-align: center;
    margin:0px;
  }
  .profiles-image {
    border: 5px solid #f8424283;
    width:170px;    
    height:170px;  
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
  }
  .profiles-image > img {
     max-width: 100%;
     -webkit-transform: scale(1.8);
             transform: scale(1.8);
     position: relative;
     top: 30px;  
  }
  .profiles-details {
    color:#4F565A;
    margin-top:15px;
    letter-spacing: 1px;
  }
  .profiles-details > h1 {
    font-weight:600;
    font-size:32px;
    margin-bottom:5px;
    color: rgba(241, 21, 21, 0.979);
  }
  /* .profile-details > h3 {
    font-weight : 300;
    font-size: 30px;
    margin: 2px
  } */
  .profiles-details > p {
      font-size: 17px;
  
  }
  .overlayy {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
     
  }
  #contents-section {
    margin-top:300px;
  }
 
  .contents-details {
      font-size: 15px;
    color: rgb(150, 149, 149);
    margin-left: 10px;
    text-align: justify;
  }
 
 
  
  @media only screen and (max-width: 768px) {
    
    .contents-heading {
      text-align: left;
    }
    
    @media only screen and (width:991px) {
      .sporting {
        margin-top:-10px;
      }
    }
    
    
  }
  .list {
    list-style: none;
    font-size: 16px;
    margin:10px 0px 10px -20px;
    color:rgb(126, 126, 126)
    
  }
  .list > strong {
    color: rgba(0,0,0,0.8);
    font-weight: 700;
  }


  

body {
    background: rgb(255, 255, 255);
    font-family: 'Open Sans', sans-serif;
  }
  /* .container {
    max-width: 960px;
    margin: 30px auto;
    padding: 20px;
  } */
  
 
  .avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 10px auto;
  }
  .avatar-upload .avatar-edit {
    position: absolute;
    right: 85px;
    z-index: 1;
    top: 3px;
  }
  #imagePreview{
      background-image: url('https://assets.webiconspng.com/uploads/2016/11/avatar_guy_male_man_person_user_icon_984123.png');
  }
  .avatar-upload .avatar-edit input {
    display: none;
  }
  .avatar-upload .avatar-edit input + label {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
  }
  .avatar-upload .avatar-edit input + label:hover {
    background: #ececec;
    border-color: #ececec;
  }
  .avatar-upload .avatar-edit input + label:after {
    content: "\f040";
    font-family: 'FontAwesome';
    color: #8d8d8d;
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
  }
  .avatar-upload .avatar-preview {
    width: 90px;
    height: 90px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .avatar-upload .avatar-preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  #img-responsive
  {
    background-image: url('https://demo.at-elog.com/assets/user-profile-images/default.png');
  }
.error-container {
  text-align: center;
  font-size: 106px;
  font-family: 'Catamaran', sans-serif;
  font-weight: 800;
  margin: 70px 15px;
}
.error-container > span {
  display: inline-block;
  position: relative;
}
.error-container > span.four {
  width: 136px;
  height: 43px;
  border-radius: 999px;
  background:
    linear-gradient(140deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 43%, transparent 44%, transparent 100%),
    linear-gradient(105deg, transparent 0%, transparent 40%, rgba(0, 0, 0, 0.06) 41%, rgba(0, 0, 0, 0.07) 76%, transparent 77%, transparent 100%),
    linear-gradient(to right, #f0213c, #f14b61, #CC9AA6, #f0213c);
}
.error-container > span.four:before,
.error-container > span.four:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 999px;
}
.error-container > span.four:before {
  width: 43px;
  height: 156px;
  left: 60px;
  bottom: -43px;
  background:
    linear-gradient(128deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 40%, transparent 41%, transparent 100%),
    linear-gradient(116deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 50%, transparent 51%, transparent 100%),
    linear-gradient(to top, #f0213c, #f14b61, #f18aa2, #f14b61, #f0213c);
}
.error-container > span.four:after {
  width: 137px;
  height: 43px;
  -webkit-transform: rotate(-49.5deg);
          transform: rotate(-49.5deg);
  left: -18px;
  bottom: 36px;
  background: linear-gradient(to right, #f0213c, #f14b61, #CC9AA6, #f14b61, #f0213c);
}

.error-container > span.zero {
  vertical-align: text-top;
  width: 156px;
  height: 156px;
  border-radius: 999px;
  background: linear-gradient(-45deg, transparent 0%, rgba(0, 0, 0, 0.06) 50%,  transparent 51%, transparent 100%),
    linear-gradient(to top right, #f0213c,#f14b61 , #f74159bd, #CC9AA6, #f74159bd,#f14b61, #f0213c);
  overflow: hidden;
  -webkit-animation: bgshadow 5s infinite;
          animation: bgshadow 5s infinite;
}
.error-container > span.zero:before {
  content: '';
  display: block;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 90px;
  height: 90px;
  background-color: transparent;
  left: 0px;
  bottom: 0px;
  background:
    linear-gradient(95deg, transparent 0%, transparent 8%, rgba(0, 0, 0, 0.07) 9%, transparent 50%, transparent 100%),
    linear-gradient(85deg, transparent 0%, transparent 19%, rgba(0, 0, 0, 0.05) 20%, rgba(0, 0, 0, 0.07) 91%, transparent 92%, transparent 100%);
}
.error-container > span.zero:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 999px;
  width: 70px;
  height: 70px;
  left: 43px;
  bottom: 43px;
  background: #FDFAF5;
  box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.screen-reader-text {
    position: absolute;
    top: -9999em;
    left: -9999em;
}
    
@-webkit-keyframes bgshadow {
  0% {
    box-shadow: inset -160px 160px 0px 5px rgba(233, 19, 19, 0.466);
  }
  45% {
    box-shadow: inset 0px 0px 0px 0px rgba(231, 30, 30, 0.507);
  }
  55% {
    box-shadow: inset 0px 0px 0px 0px rgba(223, 49, 49, 0.445);
  }
  100% {
    box-shadow: inset 160px -160px 0px 5px rgba(233, 19, 19, 0.466);
  }
}
    
@keyframes bgshadow {
  0% {
    box-shadow: inset -160px 160px 0px 5px rgba(233, 19, 19, 0.466);
  }
  45% {
    box-shadow: inset 0px 0px 0px 0px rgba(231, 30, 30, 0.507);
  }
  55% {
    box-shadow: inset 0px 0px 0px 0px rgba(223, 49, 49, 0.445);
  }
  100% {
    box-shadow: inset 160px -160px 0px 5px rgba(233, 19, 19, 0.466);
  }
}

/* demo stuff */
* {
    box-sizing: border-box;
}

.h1s {
  text-align: center;
  margin: 30px 15px;
  color: red;
}
.zoom-area { 
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}
.anchor {
  font-family: 'Poppins', sans-serif;
  display: block;
  border: 1px solid rgba(255, 0, 0, 0.788);
  text-align: center;
  position: relative;
  cursor: pointer;
  width: 220px;
  padding: 9px;
  transition: .55s;
  color: black;
border-radius: 10px ;
margin-left: 44%;
}

.anchor:hover {
/* background-color: rgba(255, 0, 0, 0.788); */
border: none;
background:
linear-gradient(140deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 43%, transparent 44%, transparent 100%),
linear-gradient(105deg, transparent 0%, transparent 40%, rgba(0, 0, 0, 0.06) 41%, rgba(0, 0, 0, 0.07) 76%, transparent 77%, transparent 100%),
linear-gradient(to right, #f0213c,  rgba(233, 19, 19, 0.795), #f02453, #f0213c);
color: white;
}

.anchor:hover:after {
width: 0;
background-color: #ffffff;
}

.anchor:hover:before {
right: 10px;
border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}

.anchor:before, .anchor:after {
position: absolute;
  display: block;
  content: "";
  z-index: 2;
  transition: .35s;
}
.anchor:before {
    top: 17px;
  left: 30px;
  width: 7px;
  height: 7px;
  -webkit-transform: translateX(-10px) rotate(-45deg);
  transform: translateX(-10px) rotate(135deg);
  border-bottom: 1px solid #504b4b;
  border-right: 1px solid #504b4b;
}

.anchor:after {
  top: 19px;
  left: 25px;
  width: 35px;
  height: 1px;

  background-color: #504b4b;
  /* background-color: rgba(0, 0, 0, 0.74); */
}
.leyenda{
	font-family: 'Caveat', cursive;
	color: rgba(0, 0, 0, 0.712);
	font-size: 2.6em;
	margin-top: -0.9em;
  padding-bottom: 35px;
}



.cd { 
    box-sizing: border-box;
     display: flex;
      align-items: center;
       justify-content: center;
        /* background-color: white; */
        z-index: 5000;
  position: relative;
  /* top: 30%;
  left: 42%; */
  padding: 1em;
  /* background-color: rgba(0, 0 , 0 ,0.2); */
  /* margin-left: auto;
  margin-right: auto; */
  top: 30%;
  left: auto;
  
  }
  
  
  
  .svg-calLoader {
     width: 200px;
      height: 200px;
    
    -webkit-transform-origin: 115px 115px;
    
            transform-origin: 115px 115px;
    -webkit-animation: 1.4s linear infinite loader-spin;
            animation: 1.4s linear infinite loader-spin;
  }
  
  .cal-loader__plane { 
    fill: rgb(90, 88, 88); 
  }
  
  .cal-loader__path {
     stroke: #ed1c23c0 ;
     -webkit-animation: 1.4s ease-in-out infinite loader-path;
             animation: 1.4s ease-in-out infinite loader-path; 
    }
  
  @-webkit-keyframes loader-spin {
    to{
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes loader-spin {
    to{
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader-path {
    0%{
      stroke-dasharray:  0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
    50%{
      stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
    }
    100%{
      stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
  }
  @keyframes loader-path {
    0%{
      stroke-dasharray:  0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
    50%{
      stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
    }
    100%{
      stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
  }
