/*   color variables */
$clr-primary: #81d4fa;
$clr-primary-light: #e1f5fe;
$clr-primary-dark: #4fc3f7;
$clr-gray100: #f9fbff;
$clr-gray150: #f4f6fb;
$clr-gray200: #eef1f6;
$clr-gray300: #e1e5ee;
$clr-gray400: #767b91;
$clr-gray500: #4f546c;
$clr-gray600: #2a324b;
$clr-gray700: #161d34;
$clr-pending: #fff0c2;
$clr-pending-font: #a68b00;
$clr-unpaid: #ffcdd2;
$clr-unpaid-font: #c62828;
$clr-paid: #fd6e6e;
$clr-paid-font: #fcfcfc;
$clr-link: #2962ff;

/*   border radius */
$radius: 0.2rem;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// body {
//   font-family: Mukta, sans-serif;
//   height: 100vh;
//   display: grid;
//   justify-content: center;
//   align-items: center;
//   color: $clr-gray500;
//   font-size: 0.9rem;
//   background-color: $clr-gray100;
// }

.thisTable {
  border-collapse: collapse;
  box-shadow: 0 5px 10px $clr-gray300;
  background-color: white;
  overflow: auto;
  

  thead {
    box-shadow: 0 5px 10px $clr-gray300;
  }

  th {
    // padding: 1rem 1.2rem;
    text-transform: uppercase;
    // letter-spacing: 0.1rem;
    // font-size: 0.7rem;
    font-weight: 900;
    border: 1px solid rgba(53, 52, 52, 0.548);

  }

  td {
    border: 1px solid rgb(255, 255, 255);
    
}

.paid{
    background-color: rgba(207, 193, 193, 0.26);
    color: rgb(255, 0, 0);

}
.paide{
    background-color: rgba(240, 44, 44, 0.829);
    color: white;
    border: 1px solid rgba(240, 44, 44, 0.658);

}
.unpaid{
    background-color: #adadad13;

}
  .status {
    border-radius: $radius;
    padding: 0.5rem 1rem;
    text-align: center;

    &-pending {
      background-color: $clr-pending;
      color: $clr-pending-font;
    }

    &-paid {
      background-color: $clr-paid;
      color: $clr-paid-font;
    }

    &-unpaid {
        background-color: #adadad13;
        color: rgb(255, 0, 0);
        border: 1px solid rgba(175, 139, 139, 0.658);
    }
  }
  .statusa {
    border-radius: $radius;
    padding: 0.5rem 0.5rem;
    text-align: center;

    &-pending {
      background-color: $clr-pending;
      color: $clr-pending-font;
    }

    &-paid {
      background-color: $clr-paid;
      color: $clr-paid-font;
    }

  
  }

  .amount {
    text-align: right;
  }

  //Color alternating rows
//   tr:nth-child(even) {
//     background-color: $clr-gray150;
//   }
}


:root {
	--link-1: rgb(221, 51, 51);
	--text: #161515;
}

// Reset

.anchs {
	text-decoration: none;
	color: var(--text);
	font-weight: 700;
	vertical-align: top;
}



// Style 2
#style-2 {
	position: relative;
	transition: color .3s ease-in-out;
	
	&::before {
		content: '';
		position: absolute;
		top: 100%;
		width: 100%;
		height: 3.5px;
		background-color: var(--link-1);
		transform: scaleX(0);
		transition: transform .4s ease-in-out;
	}
	
	&:hover {
		color: var(--link-1);
	}
	
	&:hover::before {
		transform: scaleX(1);
	}	
}
