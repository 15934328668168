body {
    background: rgb(255, 255, 255);
    font-family: 'Open Sans', sans-serif;
  }
  /* .container {
    max-width: 960px;
    margin: 30px auto;
    padding: 20px;
  } */
  
 
  .avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 10px auto;
  }
  .avatar-upload .avatar-edit {
    position: absolute;
    right: 85px;
    z-index: 1;
    top: 3px;
  }
  #imagePreview{
      background-image: url('https://assets.webiconspng.com/uploads/2016/11/avatar_guy_male_man_person_user_icon_984123.png');
  }
  .avatar-upload .avatar-edit input {
    display: none;
  }
  .avatar-upload .avatar-edit input + label {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
  }
  .avatar-upload .avatar-edit input + label:hover {
    background: #ececec;
    border-color: #ececec;
  }
  .avatar-upload .avatar-edit input + label:after {
    content: "\f040";
    font-family: 'FontAwesome';
    color: #8d8d8d;
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
  }
  .avatar-upload .avatar-preview {
    width: 90px;
    height: 90px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .avatar-upload .avatar-preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  #img-responsive
  {
    background-image: url('https://demo.at-elog.com/assets/user-profile-images/default.png');
  }