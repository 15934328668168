.read-more-state {
	display: none;
  }
  
  .read-more-target {
	opacity: 0;
	max-height: 0;
	font-size: 0;
	transition: .5s ease;
  }
  
  .read-more-state:checked ~ .read-more-wrap .read-more-target {
	opacity: 1;
	font-size: inherit;
	max-height: 999em;
  }
  
  /* .read-more-trigger:before {
	content: 'Show more';
  }
  
  .read-more-trigger {
	content: 'Show less';
  } */
  
  .read-more-trigger {
	cursor: pointer;
	display: inline-block;
	padding: 0 .5em;
	color: #666;
	font-size: .9em;
	line-height: 2;
	/* border: 1px solid #ddd; */
	border-radius: .25em;
  }
  
  